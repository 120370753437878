body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-Regular.ttf") format("ttf"),
  url("./Montserrat-Regular.svg") format("svg"),
  url("./Montserrat-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

* {
  --color-border-1: black;
  --color-border-2: black;

  --color-bg-1: white;
  --color-bg-2: #e0e0e0;
  --color-bg-3: #b0b0b0;

  --color-text-1: black;
  --color-text-2: darkslateblue;
  --color-text-error: red;
}

.error {
  color: var(--color-text-error);
}
.disabled {
  color: var(--color-bg-3);
}

* {
  font-family: "Montserrat";
  font-size: 14pt;
}

.app {
  display: flex;
  justify-content: center;
  position: relative;
}
.settings-root {
  position: absolute;
  display: flex;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  justify-content: center;
}
.settings {
  background-color: var(--color-bg-1);
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.settings-container {
  margin: 2rem;
  display: flex;
  flex-flow: column;
  padding: 1rem;
}
.settings-group {
  display: flex;
  align-items: baseline;
  flex-basis: 5em;
  flex-direction: row;
  justify-content: space-between;
}
.settings-title {
  align-self: center;
}

.time {
  margin-left: 1rem;
  margin-right: 1rem;
}
.game {
  text-align: center;
  margin: 10px;
  width: fit-content;
  display: flex;
  flex-flow: row-reverse;
}

.field-container {
  display: flex;
  flex-flow: row;
}

.game-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1pt 0;
  height: 3pt;
}

.game-progress-bar.single {
  width: 100%;
}
.game-progress-bar.multi {
  width: 49%;
}
.game-progress-bar.multi-left {
  flex-flow: row;
}
.game-progress-bar.multi-rigth {
  flex-flow: row-reverse;
}

.field-wrapper {
  display: flex;
  flex-flow: row;
}
.field-left, .field-right {
  margin-top: 1.5rem;
}

.field {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.row {
  display: flex;
}

.col-0 {
  border-left: 1.5pt solid var(--color-border-1);
}
.row-0 {
  border-top: 1.5pt solid var(--color-border-1);
}
.row-0, .row-1, .row-3, .row-4, .row-6, .row-7 {
  border-bottom: 1pt solid var(--color-border-2);
}
.col-0, .col-1, .col-3, .col-4, .col-6, .col-7 {
  border-right: 1pt solid var(--color-border-2);
}
.row-2, .row-5, .row-8 {
  border-bottom: 1.5pt solid var(--color-border-1);
}
.col-2, .col-5, .col-8 {
  border-right: 1.5pt solid var(--color-border-1);
}

.cell {
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.cell.errored {
  color: var(--color-text-error);
}

.selected-1 {
  background-color: var(--color-bg-2);
}
.selected-2 {
  background-color: var(--color-bg-3);
}

.cell-content {
  display: flex;
  align-items: center;
  height: inherit;
  font-size: 1.6rem;
  cursor: default;
}

.numbers {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0.4rem;
}

.numbersRow {
  display: flex;
  margin-bottom: 0.1rem;
}

.number-button {
  border-radius: 1px !important;
  flex-grow: 1 !important;
  width: 1rem;
}

.number {
  font-size: 2em;
  color: var(--color-text-2);
}

.progress-bar {
  height: 100%;
  width: 100%;
  display: flex;
}
.progress-bar-container {
  overflow: hidden;
  background-color: var(--color-bg-2);
  height: 100%;
  width: 100%;
  align-self: center;
  display: flex;
}
.progress-bar-bar {
  background-color: var(--color-text-2);
  transition: all 0.2s ease-out 0.1s;
}

.progress-bar-bar.alternative {
  background-color: black;
}

.controls{
  display: flex;
  flex-flow: column;
  align-items: baseline;
}
.control-button {
  height: 2rem;
}

.version {
  color: var(--color-bg-3);
  position: absolute;
  right: 0;
  margin-right: 1rem;
}
